// auth
export const AUTH_LOGIN = "/Authenticate/AdminLogin/";
export const AUTH_FORGOT_PASS = "/Authenticate/ForgotPassword/";
export const AUTH_VERIFY_LINK = "/Authenticate/ValidateResetUrl";
export const AUTH_VALIDATE_OTP = "/Authenticate/ValidateOTP";
export const AUTH_RESET_PASSWORD = "/Authenticate/ResetPassword";
export const GET_BUILD_VERSION = "/Authenticate/GetAdminBuildVersion"

// permissions
export const GET_ALL_PERMISSIONS = "/Permission/GetUserPermissionList/";
export const GET_ALL_PERMISSIONS_ADMIN = "/Permission/GetUserPermissionListForAdmin/";
export const UPDATE_ALL_PERMISSIONS_ADMIN = "/Permission/UpdateUserPermissions/";

// clients
export const ADD_CLIENT = "/Client/AddClient/";
export const GET_CLIENTS = "/Client/GetAllClients/";
export const GET_CLIENT = "/Client/GetClientById/";
export const UPDATE_CLIENT = "/Client/UpdateClient/";
export const DELETE_CLIENT = "/Client/DeleteClient/";

// client user
export const ADD_CLIENT_USER = "/ClientUser/AddClientUser/";
export const GET_ALL_CLIENT_USERS = "/ClientUser/GetAllClientUsers/";
export const GET_CLIENT_USER = "/ClientUser/GetClientUserById/";
export const UPDATE_CLIENT_USER = "/ClientUser/UpdateClientUser/";
export const DELETE_CLIENT_USER = "/ClientUser/DeleteClientUser";
export const RESEND_VERIFYLINK_USER = "/ClientUser/ResendVerifyLink";

// rap job title
export const ADD_JOB_TITLE = "RapManagement/AddRAPJobTitle/";
export const GET_JOB_TITLES = "RapManagement/GetAllRAPJobTitles/";
export const GET_JOB_TITLE = "RapManagement/GetRAPJobTitleById/";
export const UPDATE_JOB_TITLE = "RapManagement/UpdateRAPJobTitle/";
export const DELETE_JOB_TITLE = "RapManagement/DeleteRAPJobTitle/";

// SponsorInformation
export const ADD_SPONSOR_INFO = "/SponsorInformation/AddSponsorInformation/";
export const GET_SPONSOR_INFOS = "/SponsorInformation/GetAllSponsorInformations/";
export const GET_SPONSOR_INFO = "/SponsorInformation/GetSponsorInformationById/";
export const UPDATE_SPONSOR_INFO = "/SponsorInformation/UpdateSponsorInformation/";
export const DELETE_SPONSOR_INFO = "/SponsorInformation/DeleteSponsorInformation/";

// admin user
export const ADD_ADMIN_USER = "/AdminUser/AddAdminUser/";
export const GET_ADMIN_USERS = "/AdminUser/GetAllAdminUsers/";
export const GET_ADMIN_USER = "/AdminUser/GetAdminUserById/";
export const UPDATE_ADMIN_USER = "/AdminUser/UpdateAdminUser/";
export const DELETE_ADMIN_USER = "/AdminUser/DeleteAdminUser/";

// RAP Job Titles
export const GET_RAP_JOB_TITLES = "/RapManagement/GetAllRapJobTitles/";
export const GET_RAP_JOB_TITLE = "/RapManagement/GetRapJobTitleById";
export const ADD_RAP_JOB_TITLE = "/RapManagement/AddRapJobTitle/";
export const UPDATE_RAP_JOB_TITLE = "/RapManagement/UpdateRapJobTitle/";
export const DELETE_RAP_JOB_TITLE = "/RapManagement/DeleteRapJobTitle";

// RAP Info
export const GET_RAP_INFOS = "/RapManagement/GetAllRapInfo/";
export const GET_RAP_INFO = "/RapManagement/GetRapInfoById";
export const ADD_RAP_INFO = "/RapManagement/AddRapInfo/";
export const UPDATE_RAP_INFO = "/RapManagement/UpdateRapInfo/";
export const DELETE_RAP_INFO = "/RapManagement/DeleteRapInfo";
export const GET_ALL_RAP_DOCUMENTS = "/RapManagement/GetAllRapDocuments";
export const DELETE_RAP_DOCUMENT = "/RapManagement/DeleteRapDocument";
export const GET_ALL_DOCUMENTS_TYPES = "/DropDown/GetRapDocumentTypeDropDown";
export const ADD_RAP_DOCUMENT = "/RapManagement/AddRapDocument";
export const UPDATE_RAP_DOCUMENT = "/RapManagement/UpdateRapDocument";
export const GET_RAP_DOCUMENT_BY_ID = "/RapManagement/GetRapDocumentById/";

// RAP Dropdowns
export const GET_INDUSTRY_TYPE_DROPDOWN = "/DropDown/GetIndustryTypeDropDown";
export const GET_JOB_CATEGORY_DROPDOWN = "/DropDown/GetJobCategoryDropDown";
export const GET_RAP_JOB_TITLE_DROPDOWN = "/DropDown/GetRapJobTitleDropDown";
export const GET_SPONNSOR_INFO_DROPDOWN = "/DropDown/GetSponsorInformationDropDown";
export const GET_SSA_STATES_DROPDOWN = "/DropDown/GetSSAStatesDropDown";
export const GET_EMPLOYEERAP_RTI_DROPDOWN = "/DropDown/GetEmployeeRapRtiDropDown";

// Assign RAP
export const ADD_ASSIGN_RAP = "/RapManagement/AddAssignRap/";
export const GET_ALL_ASSIGNED_RAP = "/RapManagement/GetAllAssignedRap";
export const GET_ASSIGNED_RAP = "/RapManagement/GetAssignedRapById";
export const UPDATE_ASSIGN_RAP = "/RapManagement/UpdateAssignRap/";
export const DELETE_ASSIGN_RAP = "/RapManagement/DeleteAssignRapInfo";

//Apprentice Management
export const GET_ALL_APPRENTICE_MANAGEMENT = "/ApprenticeManagement/GetAllApprentice";
export const GET_ALL_RTI_TRACKER = "/RtiTracker/GetAllRtiProgress";
export const GET_ALL_RTI_EMPLOYEERAPASSIGNED = "/RtiTracker/GetEmployeeRAPAssigned";
export const DELETE_RTI_PROGRESS = "/RtiTracker/DeleteRtiProgress";
export const GET_RTI_PROGRESS = "/RtiTracker/GetRtiProgress/";
export const ADD_RTI_PROGRESS = "/RtiTracker/AddRtiProgress/";
export const UPDATE_RTI_PROGRESS = "/RtiTracker/UpdateRtiProgress/";
export const GET_RTI_PROGRESS_BY_ID = "/RtiTracker/GetRtiProgressById/";
export const GET_APPRENTICE_RECORD = "/ApprenticeManagement/GetApendixARecords/";
export const GET_CURRENT_COMPLETION = "/RtiTracker/GetRtiProgress";

//WPS Activities
export const GET_ALL_WPS_ACTIVITIES = "/RapManagement/GetAllRapWpsActivities";
export const ADD_RAP_WPS_ACTIVITIES = "/RapManagement/AddRapWpsActivity";
export const UPDATE_RAP_WPS_ACTIVITIES = "/RapManagement/UpdateRapWpsActivity";
export const DELETE_RAP_WPS_ACTIVITIES = "/RapManagement/DeleteRapWpsActivity";
export const ADD_RAP_WAGE_RATE = "/RapManagement/AddRapWageRate";
export const GET_ALL_RAP_WAGE_RATE = "/RapManagement/GetAllRapWageRate";
export const DELETE_RAP_WAGE_RATE = "/RapManagement/DeleteRapWageRate";
export const GET_RAP_WAGE_BY_ID = "/RapManagement/GetRapWageRateById";
export const UPDATE_RAP_WAGE_RATE = "/RapManagement/UpdateRapWageRate";

//RAP RTI
export const GET_ALL_RAP_RTI = "/RapManagement/GetAllRapRti";
export const ADD_RAP_RTI = "/RapManagement/AddRapRti";
export const UPDATE_RAP_RTI = "/RapManagement/UpdateRapRti";
export const DELETE_RAP_RTI = "/RapManagement/DeleteRapRti";

//selection Procedure
export const ADD_RAP_SELECTION_PROCEDURE = "/RapManagement/AddRapSelectionProcedure";
export const GET_RAP_SELECTION_PROCEDURE_BYID = "/RapManagement/GetRapSelectionProcedureById/";

//wage rate
export const GET_RAP_WAGE_RATE_BYID = "/RAPManagement/GetAllRAPWageRate/";

//DropDown
export const GET_ALL_CLIENT_DROPDOWN = "/DropDown/GetClientsDropDown";
export const GET_ALL_JOB_TITLE_DROPDOWN = "/DropDown/GetRapJobTitleDropDown";
export const GET_ALL_INDUSTRY_DROPDOWN = "/DropDown/GetIndustryTypeDropDown";
export const GET_ALL_JOB_CATEGORY_DROPDOWN = "/DropDown/GetJobCategoryDropDown";
export const GET_ALL_SSA_STATES = "/DropDown/GetSSAStatesDropDown";
export const GET_ALL_RAPINFO = "/DropDown/GetRapInfoDropDown";
export const GET_ALL_CLIENT_ID = "/DropDown/GetClientJobTitlesDropDown";
export const GET_ALL_ETHNICITY = "/DropDown/GetEthnicityTypeDropDown";
export const GET_ALL_VETERANSTATUS = "/DropDown/GetVeteranStatusTypeDropDown";
export const GET_ALL_RACETYPE = "/DropDown/GetRaceTypeDropDown";
export const GET_ALL_EDUCATION = "/DropDown/GetEducationLevelTypeDropDown";
export const GET_ALL_DISABILITY = "/DropDown/GetDisabilityTypeDropDown";
export const GET_ALL_SPONSOR_INFORMATION = "/DropDown/GetSponsorInformationDropDown";
export const GET_ALL_CAREER_CONNECTION = "/DropDown/GetCareerConnectionTypeDropDown";
export const GET_GENDER = "/DropDown/GetGendarDropDown";
export const GET_ALL_APPRENTICE_PROGRAM = "/DropDown/GetAllRAPInfoDropDown";
export const GET_ALL_EMPLOYEE_STATUS = "/DropDown/GetEmploymentStatusDropDown";
export const GET_ALL_ROLE_TYPE = "/DropDown/GetAllRoleType";

//Apprentice-Mentor-Pairing
export const GET_ALL_CLIENT_PROJECTS = "/Client/GetAllClientProjects";
export const GET_ALL_PROJECT_MENTORS = "/Client/GetAllProjectMentors";
export const INITIATE_APPRENTICE_MENTOR_MAPPING = "/ApprenticeMentorMapping/InitateApprenticeMentorMappingBrodcast";

export const GET_ALL_APPRENTICE_MANAGEMENT_DOCUMENTS = "/ApprenticeManagement/GetAllEmployeeFormsById";
export const GET_ALL_APPRENTICE_MANAGEMENT_EMPLOYEE_BY_ID = "/ApprenticeManagement/GetEmployeeProjectById";
export const GET_ALL_APPRENTICE_ETAFORM671 = "/ApprenticeManagement/GetETAForm671Records";
export const GET_ALL_APPRENTICE_APENDIXBRECORDS = "/ApprenticeManagement/GetApendixBRecords";
export const GET_ALL_APPRENTICE_DOCUMENT_S3 = "/ApprenticeManagement/GetDocumentFromS3Bucket";
export const GET_ALL_APPRENTICE_APENDIXDRECORDS = "/ApprenticeManagement/GetApendixDRecords";
export const GET_ALL_APPRENTICE_EMPLOYEE = "/ApprenticeManagement/GetProScoreEmployee";
export const UPDATE_APPRENTICE_EMPLOYEE = "/ApprenticeManagement/UpdateProScoreEmployee";
export const ADD_EMPLOYEE_FORMS = "/ApprenticeManagement/AddEmployeeForm";
export const SEND_NOTIFICATION_FOR_DOCUMENT_SIGNATURE = "/ApprenticeManagement/SendNotificationForDocumentSignature";
export const UPDATE_PROSCORE_SIGNATURE_STATUS = "/ApprenticeManagement/UpdateProscoreSignatureStatus";
export const ADD_APPENDIX_DOCUMENT_STATUS = "/ApprenticeManagement/AddAppendixDocumentStatus";
export const UPLOAD_PANDA_DOC_DOCUMENT_TO_S3_BUCKET = "/ApprenticeManagement/UploadPandaDocDocumentToS3Bucket";
export const GET_FORM_STATUS = "/ApprenticeManagement/GetETAFormStatus";
export const GET_FORM_STATUS_DETAILS = "/ApprenticeManagement/GetApprenticeFormStatusDetail";

// Courses Enrollments
export const GET_ALL_COURSES = "/Lms/GetAllCourses";
export const CREATE_STUDENT_ENROLLMENTS = "/Lms/AddEmployeeToLMS";