import React, { useEffect, useMemo, useState, useCallback } from "react";
import ProTextInput from "../../Components/Inputs/TextField";
import {
  changeMode,
  changeModeForField,
  checkEditEnabled,
  convertFileToBase64,
  fieldTruePermission,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import ProSelectField from "../../Components/Inputs/SelectField";
import ProCheckbox from "../../Components/Inputs/CheckboxField";
import { Grid } from "@mui/material";
import { APPENDIXD_TEMPLATE_UUID, PANDADOC_API_KEY, PANDADOC_API_URI, PROSCORE_ADMIN_EMAIL, PROSCORE_ADMIN_FIRST_NAME, PROSCORE_ADMIN_LAST_NAME, STAUTES } from "../../../utils/constant";
import {
  ADD_APPENDIX_DOCUMENT_STATUS,
  ADD_ASSIGN_RAP,
  GET_ALL_APPRENTICE_APENDIXDRECORDS,
  GET_ALL_CLIENT_DROPDOWN,
  GET_ALL_CLIENT_ID,
  GET_ALL_RAPINFO,
  GET_ASSIGNED_RAP,
  GET_RAP_INFO,
  SEND_NOTIFICATION_FOR_DOCUMENT_SIGNATURE,
  UPDATE_ASSIGN_RAP
} from "../../../utils/services/apiPath";
import { getApi, postApi, putApi } from "../../../utils/services";
import { errorToast, successToast } from "../../../utils/toastHelper";
import ProButton from "../../Components/ProButton";
import { defaultDropdownConfig } from "../../../utils/dropdownHelper";
import { ApiHelper } from "../../../utils/helper";
import ReviewDocument from "../ApprenticeManagement/ApprenticeDetail/ReviewDocument";
import SettingFormsLayout from "../../Components/ProForms/ProFormsLayout/SettingFormsLayout";

const AssignRapInfoFormConfing = {
  rapInfoGuid: {
    label: "Approved Occupation Title",
    perKey: "rapInfoGuid",
    rules: { required: "Approved Occupation Title is Required!" }
  },
  category: {
    label: "Category",
    perKey: "category"
  },
  onetCode: {
    label: "ONET Code",
    perKey: "onetCode"
  },
  rapidCode: {
    label: "RAPIDS Code",
    perKey: "rapidCode"
  },
  programLength: {
    label: "Program Length",
    perKey: "programLength"
  },
  clientGuid: {
    label: "Client",
    perKey: "clientGuid",
    rules: { required: "Client is Required!" }
  },
  clientJobTitleGuid: {
    label: "Client Job Title",
    perKey: "clientJobTitleGuid"
  },
  isSSA: {
    label: "Is SSA",
    perKey: "isSSA"
  }
};

export default function AddAssignRapInfo() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [jobTitleDropDown, setJobTitleDropDown] = useState([]);
  const [rapInfoDropDown, setRapInfoDropDown] = useState([]);
  const [clientsDropDown, setClientsDropDown] = useState([]);
  const [assignRapInfoData, setAssignRapInfoData] = useState({});
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(AssignRapInfoFormConfing, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(AssignRapInfoFormConfing) });
  const { clientGuid, rapInfoGuid } = watch();
  const [documentId, setDocumentId] = useState("");
  const [formName, setFormName] = useState("");
  const [aPIKey, setAPIKey] = useState("");
  const [sendDocumentStatus, setSendDocumentStatus] = useState(false);
  const [isDocumentSent, setIsDocumentSent] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
   const [apprenticeEmail, setApprenticeEmail] = useState("");
  const [apprenticeFirstName, setApprenticeFirstName] = useState("");
  const [apprenticeLastName, setApprenticeLastName] = useState("");
  const [inputFields, setInputFields] = useState({
    approvedOccupationTitle: null,
    category: "",
    RAPIDSCode: "",
    programLength: "",
    client: null,
    clientJobTitle: null,
    isSSA: false,
    ssaStates: null,
    ONETCode: ""
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlert = () => {
    setOpenAlert(false);
  };

  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);

  const getRapInfoDropdown = async () => {
    setStatus(STAUTES.LOADING);
    const res = await getApi(GET_ALL_RAPINFO, defaultDropdownConfig);
    setRapInfoDropDown(res.map(item => ({ label: item.label, value: item.value })));
    setStatus(STAUTES.IDLE);
  };

  const getAllClients = async () => {
    setStatus(STAUTES.LOADING);
    const res = await getApi(GET_ALL_CLIENT_DROPDOWN, defaultDropdownConfig);
    setClientsDropDown(res.map(item => ({ label: item.label, value: item.value })));
    setStatus(STAUTES.IDLE);
  };

  const getAllClientId = async clientGuid => {
    setStatus(STAUTES.LOADING);
    const res = await getApi(`${GET_ALL_CLIENT_ID}/${clientGuid}`, defaultDropdownConfig);
    setJobTitleDropDown(res?.map(item => ({ label: item.label, value: item.value })));
    setStatus(STAUTES.IDLE);
  };

  const getAllRapInfoById = async rapInfoGuid => {
    setStatus(STAUTES.LOADING);
    const res = await getApi(`${GET_RAP_INFO}/${rapInfoGuid}`);
    setValue("category", res?.categoryModel?.label);
    setValue("rapidCode", res?.rapidCode);
    setValue("onetCode", res?.onetCode);
    setValue("programLength", res?.totalOTJHours);
    setStatus(STAUTES.IDLE);
  };

  useEffect(() => {
    if (clientGuid) getAllClientId(clientGuid);
  }, [clientGuid]);

  useEffect(() => {
    if (rapInfoGuid) getAllRapInfoById(rapInfoGuid);
  }, [rapInfoGuid]);

  const backAction = () => navigate("/assign-rapInfo");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const res = await postApi(`${GET_ASSIGNED_RAP}/${id}`);
    setStatus(STAUTES.IDLE);
    setAssignRapInfoData(res);
    setInputFields({
      approvedOccupationTitle: res?.rapInfoGuid,
      category: res?.category,
      RAPIDSCode: res?.rapidCode,
      programLength: res?.programLength,
      client: res?.clientGuid,
      clientJobTitle: res?.clientJobTitleGuid,
      isSSA: res?.isSSA,
      ssaStates: res?.ssaStateGuid,
      ONETCode: res?.onetCode
    });
    Object.keys(AssignRapInfoFormConfing).forEach(field => setValue(field, res[field]));
  }, [setValue, id]);

  console.log(inputFields);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const assignRAPInfoGuid = isAddMode ? null : id;
    const payload = {
      rapInfoGuid: data.rapInfoGuid,
      rapidCode: data.rapidCode || null,
      onetCode: data.onetCode || null,
      category: data.category,
      clientGuid: data.clientGuid,
      isSSA: data.isSSA || false,
      ssaStateGuid: data.clientJobTitle,
      clientJobTitleGuid: data.clientJobTitleGuid || null,
      programLength: data.programLength || null,
      assignRAPInfoGuid: assignRAPInfoGuid
    };
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADD_ASSIGN_RAP : UPDATE_ASSIGN_RAP, payload);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);

    if (error) return errorToast(error);
    successToast(`Assign Rap Info ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  const convertToNewFormat = originalObject => {
    const transformedObject = {};

    for (const key in originalObject) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);
      const isChkOrRdKey = newKey.includes("Chk") || newKey.includes("Rd");

      if (Array.isArray(originalObject[key])) {
        transformedObject[newKey] = originalObject[key].map(item => {
          if (typeof item === "object" && item !== null) return convertToNewFormat(item);
          else return { value: isChkOrRdKey ? !!item : item };
        });
      } else if (typeof originalObject[key] === "object" && originalObject[key]) {
        Object.assign(transformedObject, convertToNewFormat(originalObject[key]));
      } else {
        transformedObject[newKey] = { value: isChkOrRdKey ? !!originalObject[key] : originalObject[key] };
      }
    }

    return transformedObject;
  };

  const CreateAppendixADocument = async () => {
    if (id) {
      let response = await getApi(`${GET_ALL_APPRENTICE_APENDIXDRECORDS}/${id}`);

      const convertedObject = convertToNewFormat(response);

      uploadDocumentFromURL(convertedObject, "AppendixD", response);
    }
  };

  const uploadDocumentFromURL = async (docData, type, dataPayload) => {
    try {
      const apiKey = PANDADOC_API_KEY;
      const formName = type;
      const templateUUId = APPENDIXD_TEMPLATE_UUID;

      const uploadResponse = await fetch(`${PANDADOC_API_URI}/public/v1/documents`, {
        method: "POST",
        headers: {
          Authorization: `API-Key ${apiKey}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: formName,
          template_uuid: templateUUId,
          recipients: [
            {
              email: dataPayload.emailAddress,
              role: "employer",
              first_name: dataPayload.firstName,
              last_name: dataPayload.lastName,
              signing_order: 1
            },
            {
              email: PROSCORE_ADMIN_EMAIL,
              role: "proscoreadmin",
              first_name: PROSCORE_ADMIN_FIRST_NAME,
              last_name: PROSCORE_ADMIN_LAST_NAME,
              signing_order: 2
            }
          ],
          fields: docData,
          parse_form_fields: true
        })
      });

      if (uploadResponse.ok) {
        const resp = await uploadResponse.json();
        setApprenticeEmail(dataPayload.emailAddress);
        setApprenticeFirstName(dataPayload.firstName);
        setApprenticeLastName(dataPayload.lastName);
        setDocumentId(resp.id);
        setFormName(resp.name);
        setAPIKey(apiKey);
        GetDocument(apiKey, resp.id, dataPayload.emailAddress);
      } else {
        const errorResponse = await uploadResponse.json();
        throw new Error(`Failed to upload document: ${errorResponse.error}`);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const GetDocument = (apiKey, documentId, emailAddress) => {
    const obj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `API-Key ${apiKey}`
      }
    };

    ApiHelper(`${PANDADOC_API_URI}/public/v1/documents/${documentId}/details`, obj).then(response => {
      if (response.status === "document.draft") {
        sendDocument(apiKey, documentId, emailAddress);
      } else {
        GetDocument(apiKey, documentId, emailAddress);
      }
    });
  };

  const sendDocument = (apiKey, documentId, emailAddress) => {
    const obj = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `API-Key ${apiKey}`
      },
      body: JSON.stringify({
        to: emailAddress,
        subject: "Document for Signature",
        message: "Please review and sign the document."
      })
    };
    ApiHelper(`${PANDADOC_API_URI}/public/v1/documents/${documentId}/send`, obj).then(response => {
      setSessionId(response.recipients[1].shared_link);
      setOpen(true);
      downloadDocumentFromPandaDoc(apiKey, id, response.id, response.status, response.name);
    });
  };

  const InsertAppendixDocumentStatus = async (rAPInfoId, assignRAPInfoId, documentId, status, fileName, baseStringValue, clientId) => {
    const clientName = clientsDropDown?.find(element => element.value == clientId)?.label || "";
    console.log(clientName)
    const obj = {
      RAPInfoGuid: rAPInfoId,
      AssignRAPInfoGuid: assignRAPInfoId,
      Type: "D",
      DocumentId: documentId,
      Status: status,
      FileName: fileName,
      Base64Value: baseStringValue,
      ClientName: clientName
    };
    await postApi(ADD_APPENDIX_DOCUMENT_STATUS, obj);
  };

  const downloadDocumentFromPandaDoc = async (apiKey, assignRAPInfoId, documentId, status, fileName) => {
    fetch(`${PANDADOC_API_URI}/public/v1/documents/${documentId}/download`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `API-Key ${apiKey}`
      }
    })
      .then(async response => {
        const blob = await response.blob();
        const base64String = await convertFileToBase64(blob);
        InsertAppendixDocumentStatus(
          inputFields.approvedOccupationTitle,
          assignRAPInfoId,
          documentId,
          status,
          fileName,
          base64String,
          inputFields.client
        );
      })
      .catch(err => {
        console.error(err);
      });
  };

  const sendNotificationForDocumentSignatures = async () => {
    setSendDocumentStatus(true);
    const obj = {
      email: apprenticeEmail,
      firstName: apprenticeFirstName,
      lastName: apprenticeLastName,
      documentId: documentId,
      formName: formName
    };

    const { error } = await postApi(SEND_NOTIFICATION_FOR_DOCUMENT_SIGNATURE, obj);
    if (error) return errorToast(error);
    setSendDocumentStatus(false);
    setIsDocumentSent(true);
    setOpenAlert(true);
  };

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  useEffect(() => {
    getRapInfoDropdown();
    getAllClients();
  }, []);

  const defaultFormProps = { control, formValues: assignRapInfoData, isAddMode, handleReadValueclick };

  return (
    <Layout status={status} canView={true}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Assign Rap Info`}
        backLabel="Go Back to Assign Rap Info"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid sx={{ pb: 3 }} container spacing={2}>
          <ProSelectField {...defaultFormProps} {...formConfig.rapInfoGuid} permission={fieldTruePermission} options={rapInfoDropDown} />
          <ProTextInput {...defaultFormProps} {...formConfig.category} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.onetCode} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.rapidCode} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.programLength} permission={fieldTruePermission} />
          <ProSelectField {...defaultFormProps} {...formConfig.clientGuid} permission={fieldTruePermission} options={clientsDropDown} />
          <ProSelectField {...defaultFormProps} {...formConfig.clientJobTitleGuid} permission={fieldTruePermission} options={jobTitleDropDown} />
          <ProCheckbox {...defaultFormProps} {...formConfig.isSSA} permission={fieldTruePermission} />
        </Grid>
        {!isAddMode && (
          <Grid sx={{ pl: 2 }} container spacing={2}>
            <ProButton onClick={CreateAppendixADocument}>Create Appendix D Document</ProButton>
          </Grid>
        )}
        <ReviewDocument
          open={open}
          onClose={handleClose}
          documentId={documentId}
          apiKey={aPIKey}
          sessionId={sessionId}
          onClickSendDocument={sendNotificationForDocumentSignatures}
          SendDocumentStatus={sendDocumentStatus}
          IsDocumentSent={isDocumentSent}
          openAlert={openAlert}
          handleAlert={handleAlert}
        />
      </SettingFormsLayout>
    </Layout>
  );
}
