import React from "react";
import { Grid } from "@mui/material";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from 'prop-types';

export default function InputWrapper({ children, size = DEFAULT_SIZE }) {
  return (
    <Grid className="proInputWrapper" item md={size} xs={12}>
      {children}
    </Grid>
  );
}

InputWrapper.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
};