import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import RapInfoProfile from "./RapInfoProfile";
import WageRate from "./WageRate";
import WpsActivities from "./WpsActivities";
import RelatedInstructionDescriptions from "./RelatedInstructionDescriptions";
import SelectionProcedures from "./SelectionProcedures";
import RAPDocuments from "./RAPDocuments";

export default function RapDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const isAddMode = useMemo(() => id === "add", [id]);
  const [rapSubTitle, setRapSubTitle] = useState("");
  const tabs = isAddMode
    ? [
        {
          to: "/rapinfo",
          title: "RAP Info",
          key: "rapinfo",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        }
      ]
    : [
        {
          to: "/rapinfo",
          title: "RAP Info",
          key: "rapinfo",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        },
        {
          to: "/wagerate",
          title: "Wage Rate",
          key: "wagerate",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        },
        {
          to: "/wps-activities",
          title: "WPS Activities",
          key: "wps-activities",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        },
        {
          to: "/related-instruction-descriptions",
          title: "Related Instruction Descriptions",
          key: "related-instruction-descriptions",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        },
        {
          to: "/selection-procedures",
          title: "Selection Procedures",
          key: "selection-procedures",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        },
        {
          to: "/rap-documents",
          title: "RAP Documents",
          key: "rap-documents",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        }
      ];

  const backAction = () => {
    navigate("/rap-info");
  };

  const handleTabClick = tab => {
    navigate(`/rap-info/${id}${tab.to}`);
  };

  const getRapInfoProfileData = async id => {
    setRapSubTitle("");
  };

  useEffect(() => {
    if (!isAddMode) getRapInfoProfileData(id);
  }, [id]);

  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "RAP Info");
  }, [tab]);

  return (
    <Layout>
      {activeTab && (
        <DetailPageLayout
          backAction={backAction}
          tabs={tabs}
          title={isAddMode ? "ADD RAP INFO" : "Registered Apprenticeship Program"}
          subTitle={isAddMode ? "" : rapSubTitle}
          backLabel="Go back to Rap Info"
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        >
          {activeTab === "RAP Info" && <RapInfoProfile />}
          {activeTab === "Wage Rate" && <WageRate />}
          {activeTab === "WPS Activities" && <WpsActivities />}
          {activeTab === "Related Instruction Descriptions" && <RelatedInstructionDescriptions />}
          {activeTab === "Selection Procedures" && <SelectionProcedures />}
          {activeTab === "RAP Documents" && <RAPDocuments />}
        </DetailPageLayout>
      )}
    </Layout>
  );
}
