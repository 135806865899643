import React, { forwardRef, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Alert, Slide, Dialog, Collapse, Button, Avatar, FormHelperText } from "@mui/material";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { postApi } from "../../../utils/services";
import { AUTH_FORGOT_PASS } from "../../../utils/services/apiPath";
import { fieldTruePermission } from "../../../utils/formHelper";
import { useTheme } from "../../ContextStore/ThemeContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const forgotPasswordConfig = {
  username: {
    name: "username",
    label: "Username",
    rules: { required: "Username is required!" }
  }
};

const ForgotPassword = () => {
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [helperText, setHelperText] = useState("");
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#000000" };
  const cellStyle = theme === "dark" ? { background: "#202328", px: 4, pb: 4, pt: 10 } : { background: "#ffffff", px: 4, pb: 4, pt: 10 };
  const fontstyle = theme === "dark" ? { color: "#00B0EE" } : { color: "#1B59F8" };

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/");
  };
  const { handleSubmit, control } = useForm({});

  const handleClick = async payload => {
    const { error } = await postApi(AUTH_FORGOT_PASS, {}, { params: { username: payload.username } });
    if (error) return setHelperText("This username does not exist.");
    setMessage("A link has been send to your email.");
    handleOpenDialog();
    setHelperText("");
  };

  return (
    <>
      <ProPreLoginLayout heading="Forgot Password">
        <div className="proLogin">
          <div className="proLogin_form">
            <div className="proLogin_form_input">
              <ProTextInput {...forgotPasswordConfig.username} permission={fieldTruePermission} control={control} />
            </div>
            {helperText && (
              <FormHelperText error className="proLogin_form_error needBottomSpace">
                {helperText}
              </FormHelperText>
            )}
            <p className="proLogin_form_info" style={cellStyles}>
              Enter username to retrieve account details
            </p>
            <div className="proLogin_form_button">
              <ProButton style={{ width: "100%" }} onClick={handleSubmit(handleClick)}>
                Send me a new password
              </ProButton>
            </div>
            <div className="proLogin_form_link">
              <NavLink to="/" style={fontstyle}>
                Back to Login
              </NavLink>
            </div>
          </div>
        </div>
      </ProPreLoginLayout>
      <Dialog
        className="proLogin_dialog"
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box sx={cellStyle}>
          <Avatar className="proLogin_dialog_avatar">
            <CheckTwoToneIcon />
          </Avatar>

          {message && (
            <Collapse in={true}>
              <Alert>{message}</Alert>
            </Collapse>
          )}

          <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleCloseDialog}>
            Continue to login
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
