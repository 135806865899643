import React from "react";
import ProTextInput from "./TextField";
import { InputAdornment } from "@mui/material";
import UrlIcon from "../../Assets/IconComponents/UrlIcon";
import ReadOnlyInput from "./ReadOnlyInput";
import { URL_REG } from "../../../utils/validationExpression";
import { useTheme } from "../../ContextStore/ThemeContext";
import PropTypes from "prop-types";

export default function UrlField({ rules, mode, isAddMode, ...rest }) {
  const { theme } = useTheme();
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput {...rest} />;

  return (
    <ProTextInput
      {...rest}
      rules={{
        ...(rules || {}),
        pattern: {
          value: URL_REG,
          message: "Invalid url"
        }
      }}
      startAdornment={
        <InputAdornment position="start">
          <UrlIcon fill={theme === "dark" ? "#ffffff" : "#00050B"} />
        </InputAdornment>
      }
    />
  );
}

UrlField.propTypes = {
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  rules: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
