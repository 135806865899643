import React, { useRef, useState } from "react";
import "./Header.scss";
import logo from "../../Assets/Icons/logo.png";
import logoWhite from "../../Assets/Icons/logo-white.png";
import menuopen from "../../Assets/Icons/menu-open.png";
import menuclose from "../../Assets/Icons/menu-close.png";
import NotificationIcon from "../../Assets/IconComponents/Notification";
import SubHeader from "../SubHeader/SubHeader";
import { useNavigate } from "react-router";
import { useTheme } from "../../ContextStore/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout, userSelector } from "../../store/features/auth/authSlice";
import { Avatar, IconButton, Popover, Tooltip } from "@mui/material";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import { headerMenus } from "../../../utils/MenusConfig";

const Header = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const ref = useRef(null);
  const userData = useSelector(userSelector);
  const dispatch = useDispatch();

  const handleTabClick = tab => {
    navigate(`${tab.to}`);
  };
  const handleMenuOpen = () => setMenuOpen(!menuOpen);

  const onLogout = () => {
    dispatch(handleLogout());
  };

  const manageAccount = () => {
    setPopOverOpen(false);
    navigate("/myaccount");
  };

  const handleHome = () => {
    navigate("/dashboard");
  };

  return (
    <div className="header">
      <div className="header_main">
        <div className="header_container">
          <div className="header_logo">
            <div className="header_logo_menu-open">
              <IconButton>
                {!menuOpen ? (
                  <button onClick={handleMenuOpen} style={{ border: "none", background: "none" }}>
                    <img src={menuopen} alt="menu-open" />
                  </button>
                ) : (
                  <button onClick={handleMenuOpen}>
                    <img src={menuclose} alt="menu-open" />
                  </button>
                )}
              </IconButton>
            </div>
            <button onClick={handleHome} style={{ border: "none", background: "none" }}>
              <img src={theme === "dark" ? logoWhite : logo} alt="logo" />
            </button>
          </div>
          <div className="header_user">
            <div className="header_user_sec">
              <Tooltip placement="bottom" title="Notifications" arrow>
                <IconButton>
                  <NotificationIcon />
                </IconButton>
              </Tooltip>
            </div>
            <button className="header_user_sec" ref={ref} onClick={setPopOverOpen} style={{ border: "none", background: "none" }}>
              <IconButton>
                <Avatar className="avatarProfile" alt="user-icon" />
              </IconButton>
            </button>
            <Popover
              disableScrollLock
              anchorEl={ref.current}
              onClose={() => setPopOverOpen(false)}
              open={popOverOpen}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <div className="header_user_popover" ref={ref}>
                <div className="header_user_popover_profile">
                  <Avatar alt="user-icon" />
                  <div className="header_user_popover_profile_info">
                    <div className="header_user_popover_profile_info_name">{userData.userName}</div>
                    <div className="header_user_popover_profile_info_email">{userData.email}</div>
                  </div>
                </div>
                <button className="header_user_popover_myAccount" onClick={manageAccount} style={{ border: "none", background: "none" }}>
                  Edit Profile
                  <ChevronRightTwoToneIcon />
                </button>
                <button className="header_user_popover_logout" onClick={onLogout} style={{ border: "none", background: "none" }}>
                  <LockOpenTwoToneIcon sx={{ mr: 1 }} /> Sign Out
                </button>
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div className={`header_subheader ${menuOpen && "open"}`}>
        <div className="header_subheader_main">{<SubHeader tabs={headerMenus} onTabClick={handleTabClick} />}</div>
      </div>
    </div>
  );
};

export default Header;
