import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

export default function ProButton({ children, disabled, tooltipText, ...props }) {
  return (
    <Tooltip title={tooltipText} arrow>
      <span>
        <Button disabled={disabled} variant="contained" color="primary" {...props}>
          {children}
        </Button>
      </span>
    </Tooltip>
  );
}

ProButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string.isRequired
};
