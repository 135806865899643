import React, { useCallback, useEffect } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../Components/ProGrid/components/NameCell";
import { successToast, errorToast } from "../../../utils/toastHelper";
import { useNavigate } from "react-router-dom";
import usePageState from "../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../utils/constant";
import { GET_RAP_INFOS, DELETE_RAP_INFO } from "../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../utils/services";

const getRedirectUrl = params => {
  return `/rap-info/${params.id}`;
};

export default function RapList() {
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    data: rapData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const columns = useColumn(
    [
      {
        field: "approvedJobTitle",
        headerName: "Approved Job Title",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "industryName",
        headerName: "Industry"
      },
      {
        field: "categoryName",
        headerName: "Category"
      },
      {
        field: "rapidCode",
        headerName: "RAPIDS Code"
      },
      {
        field: "programLengthYears",
        headerName: "Program Length"
      },
      {
        field: "rapInfoGuid",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Rap Info"} params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_RAP_INFO, { params: { rapInfoId: id } });
    if (error) return errorToast(error);
    successToast("Rap Info deleted successfully.");
    getRapInfoList();
  };

  const addButtonAction = () => {
    navigate("/rap-info/add");
  };

  const getRapInfoList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_RAP_INFOS, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getRapInfoList();
  }, [getRapInfoList]);

  return (
    <Layout>
      <ProGrid
        title="Rap Info"
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.rapInfoGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        columns={columns}
        hideAddButton={false}
        hideExport={false}
        rows={rapData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Rap Info"
        addButtonAction={addButtonAction}
      />
    </Layout>
  );
}
