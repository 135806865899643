import React from "react";
import ProTextInput from "./TextField";
import { InputAdornment } from "@mui/material";
import ReadOnlyInput from "./ReadOnlyInput";
import { Percent } from "@mui/icons-material";
import PropTypes from 'prop-types';

export default function PercentField({ rules, mode, isAddMode, ...rest }) {
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  if (mode === "read" || (mode === "edit" && !canEdit)) return <ReadOnlyInput type="currency" {...rest} />;
  return (
    <ProTextInput
      {...rest}
      type="number"
      rules={{
        ...(rules || {}),
        max: { value: 100, message: "Percent should be less than 100" }
      }}
      fieldProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Percent />
          </InputAdornment>
        )
      }}
    />
  );
}

PercentField.propTypes = {
  mode: PropTypes.oneOf(['read', 'edit']).isRequired,
  rules: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
};