import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from  "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import ContactIcon from "../../../Assets/IconComponents/ContactIcon";
import RtiTracker from "./RtiTracker";
import ApprenticeDocument from "./ApprenticeDocument";
import ApprenticeProfile from "./ApprenticeProfile";
import CourseEnrollment from './CourseEnrollment';

export default function ApprenticeDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [clientSubTitle] = useState("");
  const tabs = [
    {
      to: "/profile",
      title: "Profile",
      key: "profile",
      isActive: false,
      hasArrow: false,
      icon: ProfileIcon
    },
    {
      to: "/rti-tracker",
      title: "RTI Tracker",
      key: "rti-tracker",
      isActive: false,
      hasArrow: false,
      icon: ProfileIcon
    },
    {
      to: "/documents",
      title: "Documents",
      key: "documents",
      isActive: false,
      hasArrow: false,
      icon: ContactIcon
    },
    {
      to: "/course-enrollments",
      title: "Course Enrollments",
      key: "course-enrollments",
      isActive: false,
      hasArrow: false,
      icon: ContactIcon
    }
  ];

  const backAction = () => { 
    navigate("/apprentice-management");
  };

  const handleTabClick = tab => {
    navigate(`/apprentice-management/${id}${tab.to}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "Profile");
  }, [tab]);

  return (
    <Layout>
      {activeTab && (
        <DetailPageLayout
          backAction={backAction}
          tabs={tabs}
          title={"APPRENTICE INFORMATION"}
          subTitle={clientSubTitle}
          backLabel="Go back to Apprentice Management"
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        >
          {activeTab === "Profile" && <ApprenticeProfile />}
          {activeTab === "RTI Tracker" && <RtiTracker />}
          {activeTab === "Documents" && <ApprenticeDocument />}
          {activeTab === "Course Enrollments" && <CourseEnrollment />}
        </DetailPageLayout>
      )}
    </Layout>
  );
}
