import React, { useEffect, useMemo, useState, useCallback } from "react";
import { changeMode, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProTextInput from "../../../Components/Inputs/TextField";
import usePageState from "../../../../utils/customHooks/usePageState";
import { useParams } from "react-router-dom";
import useColumn from "../../../../utils/customHooks/useColumns";
import { postApi, deleteApi, putApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import {
  ADD_RAP_WAGE_RATE,
  GET_ALL_RAP_WAGE_RATE,
  DELETE_RAP_WAGE_RATE,
  UPDATE_RAP_WAGE_RATE,
  GET_RAP_WAGE_RATE_BYID
} from "../../../../utils/services/apiPath";
import ProGrid from "../../../Components/ProGrid/v2";
const wageRateFormConfing = {
  fromHours: {
    label: "From Hours",
    perKey: "FromHours",
    rules: {
      required: "From Hours is required!",
      pattern: {
        value: /^\d+$/,
        message: "From Hours must be a numbers only."
      }
    }
  },
  toHours: {
    label: "To Hours",
    perKey: "ToHours",
    rules: {
      required: "To Hours is required!",
      pattern: {
        value: /^\d+$/,
        message: "To Hours must be a numbers only."
      }
    }
  },
  wageRate: {
    label: "Wage Rate ",
    perKey: "WageRate",
    rules: {
      required: "Wage Rate is Required!",
      pattern: {
        value: /^\d+$/,
        message: "Wage Rate must be a numbers only."
      }
    }
  }
};

export default function WageRate() {
  const { id } = useParams();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(wageRateFormConfing));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(wageRateFormConfing) });
  const defaultFormProps = { control };
  const [isAddMode, setIsAddMode] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [actionData, setActionData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: rapWageListData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "startHours",
        headerName: "Start Hours",
        renderCell: params => <NameCell params={params} onClick={onDocumentClick} />
      },
      {
        field: "endHours",
        headerName: "End Hours"
      },
      {
        field: "wageRate",
        headerName: "Wage Rate"
      },
      {
        field: "rapWageRateGuid",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const onDelete = useCallback(async params => {
    const { error } = await deleteApi(`${DELETE_RAP_WAGE_RATE}?rapWageRateId=${params}`);
    if (error) return errorToast(error);
    successToast("RAP WAGE Rate successfully");
    getRapWagesList();
  });
  const onDocumentClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    setIsAddMode(false);
    const data = {
      fromHours: params.row.startHours,
      toHours: params.row.endHours,
      wageRate: params.row.wageRate,
      rapWageRateGuid: params.row.rapWageRateGuid
    };
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };
  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };
  const resetForm = () => {};
  const onCancel = () => {
    setShowGrid(true);
    resetForm();
  };

  const getRapWagesList = useCallback(async () => {
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByDescending: true,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_RAP_WAGE_RATE, payload, { params: { rapInfoId: id } });
    if (error) return errorToast(error);

    setPageState(prevPageInfo => ({
      ...prevPageInfo,
      data:
        data?.map((item, index) => {
          return { ...item, id: index };
        }) || [],
      rowCount: totalRecords
    }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      startHours: data?.fromHours,
      endHours: data?.toHours,
      wageRate: data?.wageRate,
      rapInfoGuid: id,
      rapWageRateGuid: actionData?.rapWageRateGuid || null
    };
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADD_RAP_WAGE_RATE : UPDATE_RAP_WAGE_RATE, payload);
    if (error) return errorToast(error);
    resetForm();
    successToast(`Wage Rates Uploaded successfully.`);
    getRapWagesList();
    setIsSaving(false);
    onCancel();
  };

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const onEdit = () => setFormConfig(changeMode("edit"));

  const getWageRate = async () => {
    const payload = {
      pageIndex: 1,
      pageSize: 100,
      searchString: "",
      orderByAscending: true
      // orderCol: "wpsTitle",
      // ...sortModel
    };
    const { message } = await postApi(`${GET_RAP_WAGE_RATE_BYID}`, payload, { params: { rapInfoId: id } });
    if (message) return errorToast(message);
  };

  useEffect(() => {
    getRapWagesList();
    if (!isAddMode) onEdit();
    getWageRate();
  }, [id]);
  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.rapWageRateGuid ? "Edit" : "Add"} Wage Rate`}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      hideEdit={false}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Wage Rates"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.rapWageRateGuid,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={rapWageListData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Wage Rates"
          hideExport={false}
          hideAddButton={false}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput {...defaultFormProps} {...formConfig.fromHours} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.toHours} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.wageRate} permission={fieldTruePermission} />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
