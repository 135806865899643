import React, { forwardRef, useEffect, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Alert, Slide, Dialog, Collapse, Button, Avatar, FormHelperText } from "@mui/material";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { postApi } from "../../../utils/services";
import { AUTH_VALIDATE_OTP, AUTH_VERIFY_LINK } from "../../../utils/services/apiPath";
import { fieldTruePermission } from "../../../utils/formHelper";
import { useTheme } from "../../ContextStore/ThemeContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const validateOTPConfig = {
  Otp: {
    name: "Otp",
    label: "OTP",
    rules: { required: "OTP is required!" }
  }
};

const ValidateOtp = () => {
  const url = new URL(window.location.href);
  const key = url.searchParams.get("key");
  const openAlert = true;
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [clientGuid, setClientGuid] = useState("");
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#000000" };
  const cellStyle = theme === "dark" ? { background: "#202328", px: 4, pb: 4, pt: 10 } : { background: "#ffffff", px: 4, pb: 4, pt: 10 };
  
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/dashboard");
  };
  const { handleSubmit, control } = useForm({});

  const handleClick = async formData => {
    if (clientGuid) {
      const { error, userGuid, message } = await postApi(`${AUTH_VALIDATE_OTP}?otp=${formData?.Otp}&userGuid=${clientGuid}`);
      if (userGuid !== null) navigate("/reset-password", { state: { clientGuid: clientGuid } });
      if (error) return setHelperText("Invalid OTP");
      setMessage(message);
      setHelperText("");
    }
  };

  const handleVerifyLink = async key => {
    const { error, message, userGuid } = await postApi(AUTH_VERIFY_LINK, {}, { params: { url: key } });
    if (error) return setHelperText("Invalid Url");
    setMessage(message);
    setClientGuid(userGuid);
    setHelperText("");
  };

  useEffect(() => {
    if (key) handleVerifyLink(key);
  }, [key]);

  return (
    <>
      <ProPreLoginLayout heading="OTP">
        <div className="proLogin">
          <p className="proLogin_form_info" style={cellStyles}>
            Fill in the fields below to reset your account password.
          </p>
          <div className="proLogin_form">
            <div className="proLogin_form_input">
              <ProTextInput {...validateOTPConfig.Otp} permission={fieldTruePermission} control={control} />
            </div>
            {helperText && (
              <FormHelperText error className="proLogin_form_error needBottomSpace">
                {helperText}
              </FormHelperText>
            )}
            <div className="proLogin_form_button" style={{ marginBottom: "10px" }}>
              <ProButton style={{ width: "100%" }} onClick={handleSubmit(handleClick)}>
                Validate OTP
              </ProButton>
            </div>
          </div>
        </div>
      </ProPreLoginLayout>
      <Dialog
        className="proLogin_dialog"
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box sx={cellStyle}>
          <Avatar className="proLogin_dialog_avatar">
            <CheckTwoToneIcon />
          </Avatar>
          {message && (
            <Collapse in={openAlert}>
              <Alert>{message}</Alert>
            </Collapse>
          )}
          <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleCloseDialog}>
            Continue to login
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ValidateOtp;
