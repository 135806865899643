import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import PropTypes from "prop-types"; 
import ProButton from "../ProButton";

export default function ConfirmationModal({ show, title, message, onConfirm, onCancel, confirmBtnText, cancelBtnText }) {
  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" open={show}>
      <DialogTitle>{title || "Warning!"}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <ProButton variant="outlined" color="secondary" autoFocus onClick={onCancel}>
          {cancelBtnText || "Cancel"}
        </ProButton>
        <ProButton onClick={onConfirm}>{confirmBtnText || "Ok"}</ProButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string
};
