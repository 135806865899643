import React from "react";
import { DEFAULT_SIZE } from "../../../utils/constant";
import ReadOnlyInput from "./ReadOnlyInput";
import InputWrapper from "./InputWrapper";
import { Controller } from "react-hook-form";
import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import PropTypes from 'prop-types';
import { FormControl } from "@mui/base";

export default function ProAutoComplete({
  name,
  mode,
  label,
  rules,
  onBlur,
  control,
  options = [],
  isAddMode,
  handleSelect,
  labelKey = "",
  size = DEFAULT_SIZE,
  ...rest
}) {
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput size={size} name={name} label={label} {...rest} />;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl className="proInputWithMessage" fullWidth>
            <Autocomplete
              fullWidth
              disableClearable
              value={value}
              options={options}
              onChange={(_e, value) => onChange(value)}
              error={!!error}
              getOptionLabel={option => (labelKey ? option[labelKey] : option.label) || ""}
              renderInput={params => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  placeholder={label}
                  InputProps={{ ...params.InputProps, type: "search" }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </InputWrapper>
  );
}

ProAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['read', 'edit']).isRequired,
  label: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  isAddMode: PropTypes.bool,
  handleSelect: PropTypes.func,
  size: PropTypes.string,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
};