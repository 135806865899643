import React, { useCallback, useEffect, useState } from "react";
import ProGrid from "../../Components/ProGrid/v2";
import Layout from "../../Components/Layout";
import { STAUTES } from "../../../utils/constant";
import "./ApprenticeList.scss";
import usePageState from "../../../utils/customHooks/usePageState";
import useColumn from "../../../utils/customHooks/useColumns";
import { Alert, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { fieldTruePermission } from "../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { getApi, postApi } from "../../../utils/services";
import { GET_ALL_APPRENTICE_MANAGEMENT, GET_ALL_CLIENT_DROPDOWN, GET_FORM_STATUS_DETAILS } from "../../../utils/services/apiPath";
import { defaultDropdownConfig } from "../../../utils/dropdownHelper";
import NameCell from "../../Components/ProGrid/components/NameCell";
import { useTheme } from "../../ContextStore/ThemeContext";
import ProAutoComplete from "../../Components/Inputs/ProAutoComplete";
import PropTypes from "prop-types";
import DialogComponent from "../../Components/DialogComponent/DialogComponent";
import { errorToast } from "../../../utils/toastHelper";
import FormSectionWrapper from "../../Components/ProForms/FormSectionWrapper";

const getRedirectUrl = params => {
  return `/apprentice-management/${params.row.employeeGuid}/`;
};

const RenderTitle = ({ control, clientsData }) => {
  return (
    <Grid container spacing={2}>
      <ProAutoComplete control={control} label="Select Client" name={"client"} mode={"edit"} options={clientsData} permission={fieldTruePermission} />
    </Grid>
  );
};

RenderTitle.propTypes = {
  control: PropTypes.object.isRequired,
  clientsData: PropTypes.array.isRequired
};

const defaultClient = () => {
  return JSON.parse(sessionStorage.getItem("selectClient")) || null;
};

export default function ApprenticeManagement() {
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#000000" };
  const [clientsData, setClientsData] = useState([]);
  const [openComplete, setOpenComplete] = useState(false);
  const [openInComplete, setOpenInComplete] = useState(false);
  const [inCompleteData, setInCompleteData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { control, watch } = useForm({ defaultValues: { client: defaultClient() } });
  const {
    currentPage,
    pageSize,
    data: apprenticeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const selectedClient = watch("client");

  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "projectName",
        headerName: "Project Name"
      },
      {
        field: "jobTitle",
        headerName: "Job Title"
      },
      {
        field: "formStatus",
        headerName: "Form Status",
        renderCell: params => {
          return (
            <Box>
              {params.row.formStatus === "Completed" ? (
                <Button className="proGrid-name" variant="text" color="primary" onClick={() => setOpenComplete(true)}>
                  Completed
                </Button>
              ) : (
                <Button className="proGrid-name" variant="text" color="primary" onClick={() => handleIncomplete(params.row.employeeGuid)}>
                  In-Completed
                </Button>
              )}
            </Box>
          );
        }
      }
    ],
    true
  );

  const handleIncomplete = async id => {
    setIsLoading(true);
    setOpenInComplete(true);
    const res = await getApi(`${GET_FORM_STATUS_DETAILS}/${id}`);
    setInCompleteData(res);
    setIsLoading(false);
  };

  const formatLabel = key => {
    if (key === "ssnStatus") {
      return "SSN Status";
    }
    return key.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());
  };

  const incompleteStatuses = inCompleteData
    ? Object.entries(inCompleteData)
        .filter(([key, value]) => value && key !== "formStatus")
        .map(([key, value]) => ({
          label: formatLabel(key),
          value
        }))
    : [];

  const getApprenticeList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    if (selectedClient?.value) {
      const payload = {
        pageIndex: searchString ? 1 : currentPage + 1,
        pageSize: pageSize,
        searchString: searchString,
        orderByAscending: true,
        orderCol: "firstName",
        ...sortModel
      };
      const { data, totalRecords, error } = await postApi(GET_ALL_APPRENTICE_MANAGEMENT, payload, { params: { clientId: selectedClient.value } });
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    }
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus, selectedClient]);

  const getAllclients = async () => {
    setStatus(STAUTES.LOADING);
    const res = await getApi(GET_ALL_CLIENT_DROPDOWN, defaultDropdownConfig);
    setClientsData(res.map(item => ({ label: item.label, value: item.value })));
    setStatus(STAUTES.IDLE);
  };

  useEffect(() => {
    getAllclients();
  }, []);

  useEffect(() => {
    getApprenticeList();
  }, [getApprenticeList]);

  useEffect(() => {
    if (selectedClient) sessionStorage.setItem("selectClient", JSON.stringify(selectedClient));
  }, [selectedClient]);

  return (
    <Layout>
      <div className="apprentice-title-wrapper">
        <div className="proGrid-title apprentice-title" style={cellStyles}>
          Apprentice Management
        </div>
      </div>
      <ProGrid
        title={<RenderTitle control={control} clientsData={clientsData} />}
        loading={status === STAUTES.LOADING}
        columns={columns}
        hideExport={false}
        rows={apprenticeData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        hideAddButton={true}
        options={{
          getRowId: row => row.employeeId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
      <DialogComponent open={openComplete} onClose={() => setOpenComplete(false)} title="Apprentice Form Status">
        <Typography gutterBottom>
          <Alert>completed</Alert>
        </Typography>
      </DialogComponent>
      <DialogComponent open={openInComplete} onClose={() => setOpenInComplete(false)} title="Apprentice Form Status">
        {!isLoading && (
          <Typography gutterBottom>
            <Alert severity="error">In-Completed</Alert>
          </Typography>
        )}
        <Box>
          {isLoading ? (
            <Box sx={{  textAlign: "center" }}>
              <CircularProgress size={40} />
            </Box>
          ) : (
            <FormSectionWrapper>
              <Grid container>
                {incompleteStatuses?.map((status, index) => (
                  <Grid padding={1} item xs={12} md={4} key={Number(index)}>
                    <Typography className="typography" sx={{ fontWeight: "bold" }}>
                      {status.label}
                    </Typography>
                    <Typography className="typography">{status.value}</Typography>
                  </Grid>
                ))}
              </Grid>
            </FormSectionWrapper>
          )}
        </Box>
      </DialogComponent>
    </Layout>
  );
}
