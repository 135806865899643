import { createSlice } from "@reduxjs/toolkit";
import { STAUTES } from "../../../../utils/constant";
import { AUTH_LOGIN } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";
import { loginHelper, logoutHelper } from "../../../../utils/helper";
import { encryptText } from "../../../../utils/crypto";
import { REMOVE_PERMISSION } from "../permissions/permissionsSlice";
import dayjs from "dayjs";

const initialState = {
  isAuthenticated: false,
  isMounted: false,
  user: null,
  status: STAUTES.IDLE
};

export const handleLogin = (payload, callback) => {
  return async dispatch => {
    dispatch(SET_STATUS(STAUTES.LOADING));
    try {
      const { error, token, user } = await postApi(AUTH_LOGIN, { ...payload, password: encryptText(payload.password) });
      if (error) callback(error);
      else {
        loginHelper(token, user);
        dispatch(LOGIN(user));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      dispatch(SET_STATUS(STAUTES.IDLE));
    } catch (error) {
      callback(error.message);
      dispatch(SET_STATUS(STAUTES.ERROR));
    }
  };
};

export const handleLogout = () => {
  return async dispatch => {
    dispatch(SET_STATUS(STAUTES.LOADING));
    try {
      logoutHelper();
      dispatch(LOGOUT());
      dispatch(REMOVE_PERMISSION());
      dispatch(SET_STATUS(STAUTES.IDLE));
    } catch (error) {
      dispatch(SET_STATUS(STAUTES.ERROR));
    }
  };
};

export const handleInitialize = () => {
  return async dispatch => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const expirationTime = localStorage.getItem("expiresIn");
      if (!(accessToken && expirationTime)) return dispatch(SET_MOUNTED());
      const parsedTimestamp = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]");
      if (expirationTime < parsedTimestamp) {
        handleLogout()(dispatch);
      } else {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) dispatch(LOGIN(user));
        else handleLogout()(dispatch);
      }
      dispatch(SET_MOUNTED());
    } catch (error) {}
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGIN: (state, payload) => {
      state.isAuthenticated = true;
      state.user = payload;
    },
    LOGOUT: state => {
      state.isAuthenticated = false;
      state.user = null;
    },
    SET_STATUS: (state, action) => {
      state.status = action.payload;
    },
    SET_MOUNTED: state => {
      state.isMounted = true;
    }
  }
});

export const { LOGIN, LOGOUT, SET_STATUS, SET_MOUNTED } = authSlice.actions;

export const loginSelector = state => state.auth;
export const userSelector = state => state.auth.user;

export default authSlice.reducer;
