import * as React from "react";
import Box from "@mui/material/Box";
import { Alert, Collapse, Typography, Paper, Grid, Dialog, Button, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from 'prop-types';

const ReviewDocument = ({ open, onClose, sessionId, onClickSendDocument, SendDocumentStatus, openAlert }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ width: "100%" }}>
        <IconButton style={{ position: "absolute", right: 0, padding: "2px" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Collapse in={openAlert}>
          <Alert variant="filled" action={<IconButton aria-label="close" color="inherit" size="small" onClick={onClose}></IconButton>} sx={{ mb: 2 }}>
            Document has been sent successfully.
          </Alert>
        </Collapse>
        <Paper sx={{ p: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" component="h5">
                Review Document
              </Typography>
              <Typography variant="subtitle2"></Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{ mr: 2 }}
                disabled={SendDocumentStatus}
                onClick={onClickSendDocument}
                variant="contained"
                startIcon={SendDocumentStatus ? <CircularProgress size="1rem" /> : null}
              >
                Send for Signature
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <iframe className="pandadox-iframe" title="PDF Viewer" width="100%" src={sessionId}></iframe>
      </Box>
    </Dialog>
  );
};

ReviewDocument.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  onClickSendDocument: PropTypes.func.isRequired,
  SendDocumentStatus: PropTypes.bool.isRequired,
  openAlert: PropTypes.bool.isRequired,
};

export default ReviewDocument;
