import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { Grid } from "@mui/material";
import { DEFAULT_SIZE } from "../../../../utils/constant";
import ProTextInput from "../../../Components/Inputs/TextField";

const ClientApiFormConfing = {
  clientUser: {
    label: "Client user"
  },
  apiKey: {
    label: "API Key"
  },
  secreatKey: {
    label: "Secreat Key"
  }
};

export default function ClientApi() {
  const { id } = useParams();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(ClientApiFormConfing, "edit"));
  const { control } = useForm({ defaultValues: prepareDefaultValues(ClientApiFormConfing) });
  const [clientUsers, setClientUsers] = useState([]);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const getClientUsers = async () => {
    setClientUsers([]);
  };

  useEffect(() => {
    getClientUsers();
  }, [id]);

  const defaultFormProps = { control, formValues: {}, handleReadValueclick };

  return (
    <div className="proForm-layout">
      <div className="proForm-layout__content__wrapper">
        <div className="proForm-layout__content">
          <Grid container spacing={2} pl="16px" pt="16px">
            <ProSelectField {...defaultFormProps} {...formConfig.clientUser} options={clientUsers} />
            <Grid xs={DEFAULT_SIZE} />
            <Grid xs={DEFAULT_SIZE} />
            <ProTextInput {...defaultFormProps} {...formConfig.apiKey} disabled={true} />
            <ProTextInput {...defaultFormProps} {...formConfig.secreatKey} disabled={true} />
          </Grid>
        </div>
      </div>
    </div>
  );
}
