import { Button } from "@mui/material";
import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./DeleteCell.scss";
import ConfirmationModal from "../../ConfirmationModal";
import { useTheme } from "../../../ContextStore/ThemeContext";
import PropTypes from "prop-types";

export default function DeleteCell({ title, params, onDelete, disabled = false }) {
  const theme = useTheme();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const onConfirm = () => {
    onDelete(params.id);
    setShowConfirmationModal(false);
  };
  return (
    <>
      <Button
        className="proGrid-delete"
        variant="outlined"
        size="small"
        style={{
          border: theme === "dark" ? "none" : "",
          backgroundColor: theme === "dark" ? "#00B0EE" : "",
          color: theme === "dark" ? "#15171C" : "#FF0000"
        }} 
        onClick={openConfirmationModal}
        disabled={disabled}
      >
        <DeleteOutlineIcon fontSize="small" sx={theme === "dark" ? { color: "#15171C" } : { color: "#FF0000" }} />
      </Button>
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          title={title}
          message="Are you sure you want to delete this record?"
          onConfirm={onConfirm}
          onCancel={() => setShowConfirmationModal(false)}
          confirmBtnText={"Delete"}
        />
      )}
    </>
  );
}

DeleteCell.propTypes = {
  params: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};
