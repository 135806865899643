import React, { useMemo } from "react";
import { renderFormattedDate } from "../../../../utils/formHelper";
import PropTypes from "prop-types";

export function CurrencyCell({ value }) {
  const formatedValue = useMemo(() => {
    const numberValue = value ?? "N/A";
    if (isNaN(numberValue)) return numberValue;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(numberValue);
  }, [value]);
  return <div className="center">{formatedValue}</div>;
}

export function currencyColumnDef({ align = "center", ...rest }) {
  return {
    headerAlign: align,
    align: align,
    renderCell: params => <CurrencyCell value={params.value} />,
    ...rest
  };
}

export function DateCell({ value }) {
  const formatedValue = useMemo(() => renderFormattedDate(value), [value]);
  return <div>{formatedValue}</div>;
}

export function dateColumnDef({ align = "left", ...rest }) {
  return {
    headerAlign: align,
    align: align,
    renderCell: params => <DateCell value={params.value} />,
    ...rest
  };
}

export function PercentageCell({ value }) {
  const formatedValue = useMemo(() => {
    const numberValue = value ?? "N/A";
    if (isNaN(numberValue)) return numberValue;
    return `${Number(value).toLocaleString("en-US", { minimumFractionDigits: 2 })}%`;
  }, [value]);
  return <div className="center">{formatedValue}</div>;
}

export function percentageColumnDef({ align = "center", ...rest }) {
  return {
    headerAlign: align,
    align: align,
    renderCell: params => <PercentageCell value={params.value} />,
    ...rest
  };
}

export const formatCurrency = value => {
  if (!value) return "$0.00";
  const numberValue = typeof value === "number" ? value : 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(numberValue);
};

CurrencyCell.propTypes = {
  value: PropTypes.object.isRequired,
};
DateCell.propTypes = {
  value: PropTypes.object.isRequired,
};
PercentageCell.propTypes = {
  value: PropTypes.object.isRequired,
};