import React, { useCallback, useEffect } from "react";
import Layout from "../../../Components/Layout";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import { useNavigate } from "react-router-dom";
import { STAUTES } from "../../../../utils/constant";
import { successToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import useColumn from "../../../../utils/customHooks/useColumns";
import { DELETE_ADMIN_USER, GET_ADMIN_USERS } from "../../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../../utils/services";

const getRedirectUrl = params => {
  return `/users/${params.id}`;
};

export default function AdminUserList() {
  const navigation = useNavigate();
  const {
    currentPage,
    pageSize,
    data: adminUsersData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "userName",
        headerName: "User Name"
      },
      {
        field: "userGuid",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_ADMIN_USER, { params: { adminUserId: id } });
    if (error) return errorToast(error);
    getAdminUsers();
    successToast("Admin User deleted successfully.");
  };

  const addButtonAction = () => {
    navigation("/users/add");
  };

  const getAdminUsers = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "firstName",
      ...sortModel
    };
    const { data, error, totalRecords } = await postApi(GET_ADMIN_USERS, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getAdminUsers();
  }, [getAdminUsers]);

  return (
    <Layout canView={true}>
      <ProGrid
        title="Admin User"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={adminUsersData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Admin User"
        addButtonAction={addButtonAction}
        options={{
          getRowId: row => row.userGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
