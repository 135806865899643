import * as React from "react";
import Box from "@mui/material/Box";
import { Dialog } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { PANDADOC_API_KEY, PANDADOC_API_URI } from "../../../../utils/constant";
import { postApi } from "../../../../utils/services";
import { errorToast } from "../../../../utils/toastHelper";
import { UPDATE_PROSCORE_SIGNATURE_STATUS, UPLOAD_PANDA_DOC_DOCUMENT_TO_S3_BUCKET } from "../../../../utils/services/apiPath";
import { convertFileToBase64, downloadFile } from "../../../../utils/formHelper";
import PropTypes from 'prop-types';

const SignDocument = ({ openSignDocument, onClose, documentId, apiKey, sessionId }) => {
  useEffect(() => {
    const handleMessage = async (event) => {
      if (isValidJSON(event.data)) {
        const parsedObject = JSON.parse(event.data);
        const signedStatus = parsedObject.data.document.recipients[1].signer_state;
        const docId = parsedObject.data.document.id;
        const payload = {
          documentId: docId,
          signedStatus: signedStatus
        };
        const { error, documentKey } = await postApi(UPDATE_PROSCORE_SIGNATURE_STATUS, payload);
        if (error) return errorToast(error);
        downloadDocumentAndUPloadToAWS(PANDADOC_API_KEY, docId, documentKey);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  const downloadDocumentAndUPloadToAWS = async (apiKey, documentId, documentskey) => {
    try {
      const response = await fetch(`${PANDADOC_API_URI}/public/v1/documents/${documentId}/download`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `API-Key ${apiKey}`
        }
      });

      const blob = await response.blob();
      const base64String = await convertFileToBase64(blob);

      const obj = {
        base64String,
        documentsKey: documentskey
      };
      const { error } = await postApi(UPLOAD_PANDA_DOC_DOCUMENT_TO_S3_BUCKET, obj);
      if (error) return errorToast(error);
      downloadFile(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog
      open={openSignDocument}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ width: "100%" }}>
        <IconButton
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          <iframe className="pandadox-iframe" title="PDF Viewer" width="100%" src={sessionId}></iframe>
        </div>
      </Box>
    </Dialog>
  );
};

SignDocument.propTypes = {
  openSignDocument: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documentId: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default SignDocument;
