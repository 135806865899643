import React from "react";
import PropTypes from "prop-types";

const ProfileIcon = ({ color = "#00050B" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} fill={color}>
    <path
      stroke={color}
      strokeLinejoin="round"
      d="M1 10.334a2.667 2.667 0 0 1 2.667-2.667H9a2.667 2.667 0 0 1 2.667 2.667 1.333 1.333 0 0 1-1.334 1.333h-8A1.334 1.334 0 0 1 1 10.334Z"
    />
    <path stroke={color} d="M6.333 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
  </svg>
);

ProfileIcon.propTypes = {
  color: PropTypes.string,
};

export default ProfileIcon;
