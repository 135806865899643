import React, { useCallback, useEffect } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../Components/ProGrid/components/NameCell";
import { useNavigate } from "react-router-dom";
import { successToast, errorToast } from "../../../utils/toastHelper";
import usePageState from "../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../utils/constant";
import { GET_RAP_JOB_TITLES, DELETE_RAP_JOB_TITLE } from "../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../utils/services";

const getRedirectUrl = params => {
  return `/job-titles/${params.id}`;
};

export default function JobTitles() {
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    data: jobTitleData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn([
    {
      field: "jobTitle",
      headerName: "Job Title",
      renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
    },
    {
      field: "oneTCode",
      headerName: "ONET Code"
    },
    {
      field: "rapJobTitleGuid",
      headerName: "Actions",
      isDeleteColumn: true,
      disableExport: true,
      width: 80,
      renderCell: params => <DeleteCell title={"Delete Job Title"} params={params} onDelete={onDelete} />
    }
  ]);

  const addButtonAction = () => {
    navigate(`/job-titles/add`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_RAP_JOB_TITLE, { params: { rapJobTitleId: id } });
    if (error) return errorToast(error);
    getJobTitles();
    successToast("Job Title deleted successfully.");
  };

  const getJobTitles = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_RAP_JOB_TITLES, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getJobTitles();
  }, [getJobTitles]);

  return (
    <Layout>
      <ProGrid
        title="Job Titles"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={jobTitleData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Job Title"
        addButtonAction={addButtonAction}
        hideAddButton={false}
        hideExport={false}
        options={{
          getRowId: row => row.rapJobTitleGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
