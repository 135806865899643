export const logoutHelper = () => {
  localStorage.removeItem("userGuid");
  localStorage.removeItem("userName");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permissions");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("expiresIn");
  localStorage.removeItem("user");
  sessionStorage.clear();
};

export const loginHelper = (token, user) => {
  const { accessToken, expiresIn } = token;
  localStorage.removeItem("permissions");
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("expiresIn", expiresIn);
  localStorage.setItem("user", JSON.stringify(user));
};

export async function ApiHelper(url, data = {}) {
  return await fetch(url, data)
    .then(res => res.json())
    .then(
      result => result,
      error => error
    );
}
