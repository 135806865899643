import React from "react";
import { MuiFileInput } from "mui-file-input";
import InputWrapper from "./InputWrapper";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ReadOnlyInput from "./ReadOnlyInput";
import { useTheme } from "../../ContextStore/ThemeContext";
import PropTypes from 'prop-types';

export default function FileUploadField({ size = DEFAULT_SIZE, mode, label, isAddMode, control, name, inputProps, rules, fieldProps = {}, ...rest }) {
  const { theme } = useTheme();
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput size={size} label={label} type="file" name={name} {...rest} />;
  const selectedColor = theme === "dark" ? "#ffffff" : "#00050B";
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
          return (
            <MuiFileInput
              label={label}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error?.message || null}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder={label}
              InputProps={{
                inputProps: inputProps || {},
                startAdornment: <AttachFileIcon style={{ color: selectedColor }} />
              }}
              {...fieldProps}
            />
          );
        }}
      />
    </InputWrapper>
  );
}

FileUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['read', 'edit']).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  handleReadValueclick:PropTypes.func,
  size: PropTypes.string,
  fieldProps: PropTypes.object,
  inputProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
};