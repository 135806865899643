import React, { useCallback, useEffect } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../Components/ProGrid/components/NameCell";
import { useNavigate } from "react-router-dom";
import usePageState from "../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../utils/constant";
import { GET_ALL_ASSIGNED_RAP, DELETE_ASSIGN_RAP } from "../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../utils/services";
import { successToast, errorToast } from "../../../utils/toastHelper";

const getRedirectUrl = params => {
  return `/assign-rapInfo/${params.id}`;
};

export default function AssignRapInfo() {
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    data: assignRapInfoData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const columns = useColumn(
    [
      {
        field: "approvedJobTitle",
        headerName: "Approved Ocupation Title",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "category",
        headerName: "Category"
      },
      {
        field: "companyName",
        headerName: "Client"
      },
      {
        field: "assignRAPInfoGuid",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Job Title"} params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const addButtonAction = () => {
    navigate(`/assign-rapInfo/add`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_ASSIGN_RAP, { params: { assignRapId: id } });
    if (error) return errorToast(error);
    getAssignRapInfo();
    successToast("Job Title deleted successfully.");
  };

  const getAssignRapInfo = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_ASSIGNED_RAP, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getAssignRapInfo();
  }, [getAssignRapInfo]);

  return (
    <Layout>
      <ProGrid
        title="Assign Rap Info"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={assignRapInfoData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Assign Rap Info"
        addButtonAction={addButtonAction}
        hideAddButton={false}
        hideExport={false}
        options={{
          getRowId: row => row.assignRAPInfoGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
