import React from "react";
import PropTypes from "prop-types";
import DetailHeader from "../DetailHeader";

export default function DetailPageLayout({
  backAction,
  tabs,
  activeTab,
  handleTabClick,
  title,
  subTitle,
  backLabel,
  children,
}) {
  return (
    <div className="proDetail-wrapper">
      <DetailHeader
        title={title}
        subTitle={subTitle}
        backAction={backAction}
        backLabel={backLabel}
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />
      {children}
    </div>
  );
}

DetailPageLayout.propTypes = {
  backAction: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.string.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  backLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};